import * as React from "react";

import Layout from "../components/Layout";
import SectionContent from "../components/SectionContent";

// markup
const RaceSection = ({ location }) => {
  return (
    <Layout
      path={location.pathname}
      title="קטע מספר 11: מאנדרטת 'חץ שחור' לקיבוץ ארז"
    >
      <SectionContent title="קטע מספר 11: מאנדרטת 'חץ שחור' לקיבוץ ארז מפגש נחל שקמה">
        <div>
          <p>
            <strong>אורך הקטע: 10 ק&quot;מ</strong>
          </p>
          <p>
            <strong>רמת הקושי: קלה &#8211; בינונית</strong>
          </p>
          <p>
            <strong>תיאור הקטע</strong>:
          </p>
          <p>
            מתחנת ההחלפה עולים על כביש אספלט לכיוון צפון כ- 700 מטר עד לתחנת
            שאיבה של 'מקורות' ומיד לאחריה פונים שמאלה לדרך לבנה (לכיוון צפון).
            לאחר כ-1700 מטר פונים שמאלה בכביש אספלט. לאחר כ 500 מטר נוספים
            (ק&quot;מ 2.8) פונים שמאלה בקצה הירידה לדרך אספלט. בקצה חורשת
            האקליפטוסים נמשיך עם הדרך המתעקלת שמאלה ולאחר כ-70 מטר פונה ימינה
            לכיוון צפון. נמשיך על דרך זו עד למתקן מקרות, אותו נעקוף מימין על גבי
            דרך לבנה, שהופכת לדרך עפר, עוד כ- 200 מטר, נפנה שמאלה ונמשיך עוד כ
            300 מטר עד למפגש דרכים. נפנה ימינה (לכיוון צפון) עד למפגש עם דרך
            אספלט ישנה (צמת T). נפנה ימינה במגמת עליה לכיוון כללי מזרח. לפני
            הגשר מעל מסילת הרכבת, נפנה שמאלה (כיוון כללי צפון) לדרך עפר המקבילה
            למסילה כ-550 מטר עד למזלג דרכים. נבחר את הפניה שמאלה לדרך לבנה
            שבהמשך תהפוך לאספלט. נמשיך לרוץ על הדרך בכיוון כללי צפון מזרח כ-2
            ק&quot;מ עד למפגש דרכים נוסף (צמת T). נפנה ימינה (בכיוון כללי צפון)
            על דרך אספלט עד הכניסה לקיבוץ ארז והמשך בעוקף פרדס ממזרח עד למפגש
            נחל שקמה.
          </p>
          <p>
            <strong>טופוגרפיה</strong>: גבעות ובתרונות<strong>.</strong>
          </p>
          <p>
            <strong>הערות: ערנות יתרה לסימונים הדרך</strong> &#8211; החל
            מק&quot;מ 3 ישנם מספר צמתים ופיצולים. חלק ממקטעי הריצה חוליים
          </p>
          <p>
            <strong>מסלול</strong>: דרך לבנה, דרכי אספלט, דרכי עפר חוליות.
          </p>
          <p>
            <strong>בטיחות</strong>: ערנות לרכבים הנוסעים בדרך (3 ק&quot;מ
            ראשונים וכניסה לקיבוץ ארז), ערנות למעברי בקר (גשר צינורות) במספר
            קטעים.
          </p>
          <p>
            <strong>תחנת החלפה</strong> : בכניסה לקיבוץ ארץ (180 מטר לפני שער
            הקיבוץ) בצמוד לפרדס (מצפון)
          </p>
        </div>
        <div>
          <p>
            <iframe
              class="alltrails"
              src="https://www.alltrails.com/widget/map/--10429701?u=m"
              width="100%"
              height="400"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              title="קטע מספר 11: מאנדרטת 'חץ שחור' לקיבוץ ארז מפגש נחל שקמה"
            ></iframe>
          </p>
        </div>
      </SectionContent>
    </Layout>
  );
};

export default RaceSection;
